//given user id populate data store to edit that user
import { useDataStore } from "@/store/data";
import { useAppStore } from "@/store/app";
import axios from "axios";

export default async function populateDataStoreToEditUser(userId, cacheData) {
	const dataStore = useDataStore();
	const promises = [];

	//clear old data
	[
		"notificationPreferences",
		"contractingFields",
		"contractingDetails",
		"documents",
		"subscriptions",
		"profile",
		"editingUserDetailed",
		"onboardingState",
		"editingUser",
	].forEach(key => delete dataStore.data[key]);

	//cancel old app store onboarding refresher
	const appStore = useAppStore();
	appStore.removeDataHook("RefreshOnboardingState");
	appStore.removeDataHook("AddressUpdated");
	appStore.removeDataHook("WufChange");

	//if no userId
	if (!userId) return;

	try {
		const fetchUser = () =>
			axios.get(`api/v1/users/${userId}`).then(res => res.data);

		//if address changed
		appStore.onDataSuccess(
			"AddressUpdated",
			() => {
				//update locations
				axios
					.get(`api/v1/users/${userId}/profile/locations`)
					.then(res => res.data)
					.then(subscriptions =>
						dataStore.appendData("subscriptions", subscriptions),
					)
					.catch(console.error);

				//update profile (city)
				axios
					.get(`api/v1/users/${userId}/profile`)
					.then(res => res.data.profile)
					.then(profile => dataStore.appendData("profile", profile))
					.catch(console.error);

				//update user (timezone)
				fetchUser().then(user =>
					dataStore.appendData("editingUser", user),
				);
			},
			{ scope: "addressChange" },
		);

		//if wuf changed
		appStore.onDataSuccess(
			"WufChange",
			() => {
				//update profile (displays wuf)
				axios
					.get(`api/v1/users/${userId}/profile`)
					.then(res => res.data.profile)
					.then(profile => dataStore.appendData("profile", profile))
					.catch(console.error);

				//update user (in case blocked)
				fetchUser().then(user =>
					dataStore.appendData("editingUser", user),
				);
			},
			{ scope: "wuf-change" },
		);

		//set editing user
		const getUser = fetchUser();
		try {
			await getUser;
		} catch (err) {
			throw "User does not exist.";
		}
		promises.push(
			getUser.then(res => ({
				key: "editingUser",
				data: res,
			})),
		);

		//if they're pending load onboarding state and set app store to refresh onboarding state when required
		promises.push(
			getUser.then(user => {
				if (!user.status.includes("pending"))
					return {
						key: "onboardingState",
						data: undefined,
					};

				//apply new app store onboarding refresher
				appStore.onDataSuccess("RefreshOnboardingState", () => {
					axios
						.get(`api/v1/users/${userId}/onboarding/state`, {
							preventHooks: true,
						})
						.then(res => res.data.onboardingState)
						.then(state => {
							const oldCompleteState =
								dataStore.data?.onboardingState?._complete
									?.status;

							dataStore.appendData("onboardingState", state);

							//if complete status has changed update full user as status will also have changed
							const newCompleteState = state?._complete?.status;
							if (oldCompleteState != newCompleteState) {
								fetchUser().then(newData =>
									dataStore.appendData(
										"editingUser",
										newData,
									),
								);
							}
						})
						.catch(console.error);
				});

				//append onboarding data
				return axios
					.get(`api/v1/users/${user.user_id}/onboarding/state`)
					.then(res => ({
						key: "onboardingState",
						data: res.data.onboardingState,
					}));
			}),
		);

		const user = await getUser;

		//always get detailed info
		promises.push(
			axios.get(`api/v1/users/${userId}/detailed`).then(res => ({
				key: "editingUserDetailed",
				data: res.data.details,
			})),
		);
		//always get notificaitons stuff
		promises.push(
			axios
				.get(`api/v1/users/${userId}/notifications/preferences`)
				.then(res => ({
					key: "notificationPreferences",
					data: res.data.preferences,
				})),
		);

		switch (user.team_name) {
			case "worker": {
				//worker
				//TODO_future:	-1 -> +3 weeks of bookings (extended to full weeks)
				//	full profile and user data
				//get profile
				promises.push(
					axios.get(`api/v1/users/${userId}/profile`).then(res => ({
						key: "profile",
						data: res.data.profile,
					})),
				);
				//get locations
				promises.push(
					axios
						.get(`api/v1/users/${userId}/profile/locations`)
						.then(res => ({
							key: "subscriptions",
							data: res.data,
						})),
				);

				//get documents
				promises.push(
					axios.get(`api/v1/users/${userId}/documents`).then(res => ({
						key: "documents",
						data: res.data.documents,
					})),
				);
				//get worker contracting stuff (with fields from config)
				promises.push(
					axios.get(`api/v1/users/${userId}/worker`).then(res => ({
						key: "contractingDetails",
						data: res.data,
					})),
				);
				promises.push(
					getUser.then(user => {
						const country = user?.country;
						return axios
							.get(`api/v1/config/contracting-fields/${country}`)
							.then(res => ({
								key: "contractingFields",
								data: res.data,
							}));
					}),
				);
				break;
			}
			case "provider": {
				//provider
				//	-1 -> +3 weeks of bookings (extended to full weeks)
				//	worker profiles for above bookings
				break;
			}
			case "admin": {
				break;
			}
			default:
				throw "Unknown team";
		}

		//await all
		const resolved = await Promise.all(promises);

		//push into store
		for (let { key, data } of resolved)
			if (data) dataStore.setData(key, data, cacheData);
	} catch (err) {
		//reset and throw error
		populateDataStoreToEditUser();
		throw err;
	}
}
