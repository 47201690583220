import { createRouter, createWebHistory } from "vue-router";
import { isLoggedIn, getToken, logout } from "./auth";

import MiscRoutes from "./routes/misc";
import ErrorRoutes from "./routes/error";
import OnboardingRoutes from "./routes/onboarding";
import BookingRoutes from "./routes/booking";
import AuthRoutes from "./routes/auth";
import ProviderRoutes from "./routes/provider";
import PaymentRoutes from "./routes/payment";
import UserRoutes from "./routes/user";

import { useActiveUserStore } from "./store/activeUser";

const routes = [
	...OnboardingRoutes,
	{
		path: "/",
		component: () => import("./components/frame/src/DefaultFrame.vue"),
		name: "Default",
		children: [
			...MiscRoutes,
			...BookingRoutes,
			...AuthRoutes,
			...ProviderRoutes,
			...UserRoutes,
			...PaymentRoutes,
			...ErrorRoutes,
		],
	},
	// Define other routes as needed
];

const router = createRouter({
	history: createWebHistory(),
	routes,
});

// Define a function to check if a route requires authentication
export const allowedWithoutAuth = [
	"404",
	"UnauthorisedAccess",
	"UserLogin",
	"UserRegistration",
	"RegistrationConfirmation",
	"VerifyEmail",
	"VerifyEmailSuccess",
	"ResetPassword",
	"AcceptInvite",
	"XeroAutoRedirect",
	"UpdateSplashScreen",
	"OfflineSplashScreen",
	"UserReference",
	"UserReferenceSpecific",
];
const requiresAuth = routeName => {
	return !allowedWithoutAuth.includes(routeName);
};

const allowedWithoutPermissions = [
	...allowedWithoutAuth,
	"Home",
	"FileViewer",
	"PromptNotifications",
	"PromptInstall",
];

router.beforeEach((to, from, next) => {
	// Check if the route requires authentication
	if (requiresAuth(to.name) && !isLoggedIn()) {
		logout();
		next({ name: "UserLogin", query: { redirect: to.fullPath } });
		return;
	}

	if (to.meta.requiresAuth && !isLoggedIn()) {
		next({ name: "UserLogin", query: { redirect: to.fullPath } });
		return;
	}

	//ensure have permission to access page
	//TODO: remove menu items as default permissions and put permissions onto every route (note: routes inherit meta from parent so ensure inheriting doesn't cause issues)
	const token = getToken();
	const activeUserStore = useActiveUserStore();
	if (!allowedWithoutPermissions.includes(to.name) && token) {
		if (to?.meta?.allowedTeams) {
			//if permissions within route
			const ok = activeUserStore.checkPermissions(
				...to.meta.allowedTeams,
			);
			if (!ok) return next({ name: "UnauthorisedAccess" });
		} else {
			//if within their menu
			const items = [
				...activeUserStore.mainMenu,
				...activeUserStore.accountMenu,
			];
			if (items.every(item => !to.fullPath.includes(item.link)))
				return next({ name: "UnauthorisedAccess" });
		}
	}

	next();
});

export default router;
